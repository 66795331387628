
const Colors = {
  Dark_Green: "#2C3631",
  Light_Green: "#E4EFE9",
  Soft_Green: "#E4EFE9",
  Soft_Grey: "#CDCDCD",
  Soft_Grey2: "#AAAAAA"
};

export default Colors;
